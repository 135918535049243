@import "../../App.scss";
@import url('https://fonts.googleapis.com/css2?family=Konkhmer+Sleokchher&display=swap');
$timing: .75s;

.loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    position: relative;
    -webkit-text-stroke: 3px $primary;
    color: transparent;
    font-size: 20rem;
    font-family: "Konkhmer Sleokchher", sans-serif;
    font-weight: 500;
    height: 50px;
    overflow: hidden;
    animation: $timing grow $timing ease forwards,
      $timing fade calc($timing * 3) ease forwards;
    &::before {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      color: $primary;
      height: 10px;
      width: 10px;
      animation: $timing ease expand forwards,
        $timing collapse calc($timing * 2) ease forwards;
      background: $primary;
    }
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      content: "C";
      color: $primary;
      height: 0;
      overflow: hidden;
      animation: $timing ease calc($timing * 2) fill forwards;
    }
  }
}

#root{
    animation: $timing transition calc($timing * 3) ease forwards;
}

@keyframes transition{
    to{
        background: $background;
    }
}

@keyframes fill {
  to {
    height: 100%;
  }
}

@keyframes grow {
  to {
    height: calc(1.25em + 20px);
  }
}

@keyframes expand {
  to {
    width: 100%;
  }
}

@keyframes collapse {
  to {
    width: 0%;
  }
}

@keyframes fade {
  to {
    opacity: 0;
  }
}
