 @import "../../App.scss";

.mobile-nav{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
    aside{
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100vw;
        height: 50px;
        backdrop-filter: blur(5px);
        z-index: 2;
    }
    ion-icon{
        color: $primary;
        font-size: 3rem;
        margin-right: 10px;
        &:active{
            color: white;
        }
        &:hover{
            cursor: pointer;
        }
    }
    ul{
        transform: translateY(-100%);
        transform-origin: top;
        position: fixed;
        height: 100vh;
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        list-style: none;
        gap: 4rem;
        pointer-events: none;
        transition: all .2s ease;
        background: black;

        &.active{
            pointer-events: unset;
            transform: translateY(0);
        }
        li:hover{
            cursor: pointer;
        }
        li > a{
            text-transform: uppercase;
            font-family: $primary-font;
            font-size: 2rem;
            text-decoration: none;
            color: $primary;
            &:active{
                color: white;
            }
        }
    }
}
