@import "../../App.scss";

$letter-color: $primary;
$letter-outline: $background;

#Contact {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 150px;
  h1 {
    -webkit-text-stroke: 3px white;
    font-size: clamp(3rem, 8vw, 6rem);
    text-transform: capitalize;
    color: transparent;
    span{
        display: inline-block;
    }
  }
}

.contact {
  position: relative;
  border-radius: 2px;
  -ms-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  -webkit-border-radius: 2px;
}
.contact .envelope {
  position: absolute;
  height: 93px;
  width: 165px;
  left: 50%;
  margin-left: -83px;
  top: 50%;
  margin-top: -50px;
  background: $background;

  transition: margin-top 300ms;
  -ms-transition: margin-top 300ms;
  -moz-transition: margin-top 300ms;
  -o-transition: margin-top 300ms;
  -webkit-transition: margin-top 300ms;
}
.contact:hover .envelope {
  transition-delay: 150ms;
  -ms-transition-delay: 150ms;
  -moz-transition-delay: 150ms;
  -o-transition-delay: 150ms;
  margin-top: -20px;
}
.contact .envelope .top {
  position: absolute;
  top: -3px;
  left: 0px;
  width: 100%;
  height: 73px;
  z-index: 30;
  overflow: hidden;

  transform-origin: top;
  -ms-transform-origin: top;
  -moz-transform-origin: top;
  -o-transform-origin: top;
  -webkit-transform-origin: top;

  transition: transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms,
    top 300ms 0ms;
  -ms-transition: -ms-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms,
    top 300ms 0ms;
  -moz-transition: -moz-transform 300ms 150ms, z-index 0ms 150ms,
    height 300ms 0ms, top 300ms 0ms;
  -o-transition: -o-transform 300ms 150ms, z-index 0ms 150ms, height 300ms 0ms,
    top 300ms 0ms;
  -webkit-transition: -webkit-transform 300ms 150ms, z-index 0ms 150ms,
    height 300ms 0ms, top 300ms 0ms;
}
.contact:hover .envelope .top {
  transition: transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
  -ms-transition: -ms-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
  -moz-transition: -moz-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
  -o-transition: -o-transform 300ms 0ms, height 300ms 150ms, top 300ms 150ms;
  -webkit-transition: -webkit-transform 300ms 0ms, height 300ms 150ms,
    top 300ms 150ms;

  height: 10px;
  top: -80px;

  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  -webkit-transform: rotateX(180deg);
}
.contact .envelope .outer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  border-left: 82px solid transparent;
  border-right: 82px solid transparent;
  border-top: 70px solid $letter-outline;
}
.contact .envelope .outer .inner {
  position: absolute;
  left: -81px;
  top: -73px;
  border-left: 81px solid transparent;
  border-right: 80px solid transparent;
  border-top: 68px solid $letter-color;
}
.contact .envelope .bottom {
  position: absolute;
  z-index: 20;
  bottom: 0px;
  left: 2px;
  border-left: 81px solid transparent;
  border-right: 80px solid transparent;
  border-bottom: 45px solid $letter-color;
}
.contact .envelope .left {
  position: absolute;
  z-index: 20;
  top: 0px;
  left: 0px;
  border-left: 81px solid $letter-color;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}
.contact .envelope .right {
  position: absolute;
  z-index: 20;
  top: 0px;
  right: 0px;
  border-right: 80px solid $letter-color;
  border-top: 45px solid transparent;
  border-bottom: 45px solid transparent;
}
.contact .envelope .cover {
  position: absolute;
  z-index: 15;
  bottom: 0px;
  left: 0px;
  height: 55%;
  width: 100%;
  background: $letter-outline;
}
.contact .envelope .paper {
  position: absolute;
  height: 83px;
  padding-top: 10px;
  width: 100%;
  top: 0px;
  left: 0px;
  background: $background;
  border: 3px solid $primary;
  border-bottom: none;
  z-index: 10;
  transition: margin-top 300ms 0ms;
  -ms-transition: margin-top 300ms 0ms;
  -moz-transition: margin-top 300ms 0ms;
  -o-transition: margin-top 300ms 0ms;
  -webkit-transition: margin-top 300ms 0ms;
}
.contact:hover .envelope .paper {
  margin-top: -80px;
  transition: margin-top 300ms 150ms;
  -ms-transition: margin-top 300ms 150ms;
  -moz-transition: margin-top 300ms 150ms;
  -o-transition: margin-top 300ms 150ms;
  -webkit-transition: margin-top 300ms 150ms;
}
.contact .envelope .paper a {
  position: relative;
  display: block;
  font-size: 1.2rem;
  padding:5px;
  margin-bottom: 3px;
  text-align: center;
  color: #333;
  text-decoration: none;
}
.contact .envelope .paper a.call .i {
  position: absolute;
  top: 2px;
  left: 20px;
  display: inline-block;
  width: 3px;
  height: 5px;
  border-width: 5px 0 5px 2px;
  border-style: solid;
  border-color: #555;
  background: $background;

  transform: rotate(-30deg);
  -ms-transform: rotate(-30deg);
  -moz-transform: rotate(-30deg);
  -o-transform: rotate(-30deg);
  -webkit-transform: rotate(-30deg);

  border-top-left-radius: 3px 5px;
  border-bottom-left-radius: 3px 5px;
  -moz-border-radius-topleft: 3px 5px;
  -moz-border-radius-bottomleft: 3px 5px;
  -webkit-border-top-left-radius: 3px 5px;
  -webkit-border-bottom-left-radius: 3px 5px;

  transition: border-color 300ms;
  -ms-transition: border-color 300ms;
  -moz-transition: border-color 300ms;
  -o-transition: border-color 300ms;
  -webkit-transition: border-color 300ms;
}
.contact .envelope .paper a {
  color: $primary;

  transition: color 200ms;
  -ms-transition: color 200ms;
  -moz-transition: color 200ms;
  -o-transition: color 200ms;
  -webkit-transition: color 200ms;
}
.contact .envelope .paper a:hover {
  color: #eee;
}
.contact .envelope .paper a.call:hover .i {
  border-color: #ddd;
}
.contact .envelope .paper a.mail .i {
  position: absolute;
  top: 0px;
  left: 17px;
  display: inline-block;
  font-size: 13px;
  font-weight: bold;
}
