@import "../../App.scss";

nav {
  position: fixed;
  right: 50px;
  top: 30px;
  z-index: 99;
  ul {
    position: relative;
    list-style: none;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    li {
      position: relative;
      font-size: 1rem;
      text-align: right;
      font-family: $primary-font;
      margin-top: 20px;
      overflow: hidden;
      opacity: 0;
      transform: translateX(-100px);
      &.active{
        a {
          transform: translateY(100%);
          color: $nav-active;
        }
        ion-icon {
          top: 50%;
          transform: translateY(-50%);
        }
      }
      &:nth-child(1).active ~ aside > .marker {
        top: 5px;
      }
      &:nth-child(2).active ~ aside > .marker {
        top: 40px;
      }
      &:nth-child(3).active ~ aside > .marker {
        top: 80px;
      }
      &:nth-child(4).active ~ aside > .marker {
        top: 120px;
      }

      ion-icon {
        position: absolute;
        color: $primary;
        right: 10px;
        top: -150%;
        font-size: 1.5em;
        transition: all 0.2s ease;
      }
      a {
        display: inline-block;
        text-decoration: none;
        color: $nav-inactive;
        transition: color, transform 0.2s ease;
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: all 0.2s ease;
        font-weight: 300;
        &:hover {
          color: $primary;
          padding-right: 10px;
          font-size: 1.1rem;
        }
      }
    }
    aside {
      position: absolute;
      right: -25px;
      height: 0;
      width: 3px;
      top: 0;
      background: $primary;
      .marker {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 10px;
        opacity: 0;
        animation: .8s fadeIn .7s ease forwards;
        width: 10px;
        border-radius: 50%;
        background: $background;
        transition: all .3s ease;
        border: 2px solid $primary;
      }
    }
  }
}
@keyframes fadeIn{
    to{
        opacity: 1;
    }
}
