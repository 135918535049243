@import "../../App.scss";

.contact-bar {
  position: fixed;
  bottom: 0;
  left: 30px;
  ul {
    li {
      list-style: none;
      font-size: 2.25rem;
      margin-bottom: 30px;
      transform: translateY(100px) rotate(180deg);
      opacity: 0;
      a {
        color: $primary;
        ion-icon {
          transition: color, transform 0.2s ease;
          &:hover {
            cursor: pointer;
            color: white;
            transform: translateY(-5px);
          }
        }
      }
    }
  }
  .line {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background: $primary;
    width: 3px;
    height: 0;
  }
}
