@import "./App.scss";
* {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0;
  box-sizing: border-box;
  font-family: $primary-font;

  //ScrollBar
  &::-webkit-scrollbar{
    width: 5px;
  }
  &::-webkit-scrollbar-track {
      background: transparent; /* Background of the track */
      border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #16bfe4;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
      background: #e4e4e4; /* Color of the thumb on hover */
  }


}

html {
  scroll-behavior: smooth;
  background: black;
}
section{
  margin: clamp(100px, 15vw, 300px) 20px;
  &#Contact{
    margin-bottom: clamp(200px, 30vw, 400px)
  }
  &#Home{
    margin-top: clamp(50px, 30vw, 100px);
  }
}
#root {
  position: relative;
  background: black;
  overflow-x: hidden;
  padding: 0 150px;
  @media screen and (max-width: 920px) {
    padding: 0 20px;
  }
}

p, li{
    line-height: 1.5em;
}
