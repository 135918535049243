@import "../../App.scss";

section[id="Home"] {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 5rem;
  article {
      margin: 0 3rem;
    img {
      max-width: 500px;
      min-width: 200px;
      width: 100%;
      border-radius: 50%;
      border: 5px solid $primary;
      opacity: 0;
    }
    p {
      text-align: center;
      margin-top: 10px;
      font-size: 1.2rem;
      color: white;
      font-family: $primary-font;
      opacity: 0;
      span {
        color: $primary;
      }
    }
  }
  a{
      opacity: 0;
  }
  a > button {
    position: relative;
    text-transform: uppercase;
    font-size: clamp(1rem, 3vw, 1.7rem);
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: 3px solid $primary;
    color: $primary;
    letter-spacing: 3px;
    font-weight: 500;
    transition: all 0.2s ease;

    &::after {
      content: attr(data-text);
      position: absolute;
      height: 100%;
      width: calc(100% + 6px);
      bottom: -100%;
      left: 50%;
      transform: scaleX(-1) rotate(180deg) translate(-50%);
      color: white;
      opacity: 0;
      background: linear-gradient(to top, $primary, transparent);
      transition: all 0.3s ease;
      pointer-events: none;
    }
    &:hover {
      cursor: pointer;
      background: $primary;
      color: white;
      box-shadow: 0 0 20px 2px rgba(22, 191, 228, 0.8),
        0 0 50px 10px rgba(22, 191, 228, 0.4),
        0 0 100px 40px rgba(22, 191, 228, 0.2);

      &::after {
        opacity: 0.4;
        height: 100%;
      }
    }
  }
}
