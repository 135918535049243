@import "../../../App.scss";

.more-work{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#projects-container {
    padding-top: 100px;
    display: grid;
  gap: 3rem;
  grid-template-columns: repeat(2, minmax(300px, 400px));
  min-height: 100vh;
  @media screen and (max-width: 700px) {
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  }
}

button{
    position: relative;
    text-transform: uppercase;
    font-size: 1.7rem;
    padding: 0.75rem 1.5rem;
    background: transparent;
    border: 3px solid $primary;
    color: $primary;
    letter-spacing: 3px;
    font-weight: 500;
    transition: all 0.2s ease;
    margin-bottom: 30px;

    &::after {
      content: attr(data-text);
      position: absolute;
      height: 100%;
      width: calc(100% + 6px);
      bottom: -100%;
      left: 50%;
      transform: scaleX(-1) rotate(180deg) translate(-50%);
      color: white;
      opacity: 0;
      background: linear-gradient(to top, $primary, transparent);
      transition: all 0.3s ease;
      pointer-events: none;
    }
    &:hover {
      cursor: pointer;
      background: $primary;
      color: white;
      box-shadow: 0 0 20px 2px rgba(22, 191, 228, 0.8),
        0 0 50px 10px rgba(22, 191, 228, 0.4),
        0 0 100px 40px rgba(22, 191, 228, 0.2);

      &::after {
        opacity: 0.4;
        height: 100%;
      }
    }
  }
.project {
  position: relative;
  border: 3px solid $primary;
  border-radius: 10px;
  border-top-right-radius: 0;
  padding: 1rem;
  padding-top: 3rem;
  max-height: 400px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  opacity: 0;
  --timing: .4s;
  --delay: .15s;
  &:nth-child(1){
      animation: var(--timing) appear calc(var(--delay)*0) ease forwards;
  }
&:nth-child(2){
      animation: var(--timing) appear calc(var(--delay)*1) ease forwards;
  }
&:nth-child(3){
      animation: var(--timing) appear calc(var(--delay)*2) ease forwards;
  }
&:nth-child(4){
      animation: var(--timing) appear calc(var(--delay)*3) ease forwards;
  }
  &::before {
    content: "";
    position: absolute;
    right: -3px;
    border-top-right-radius: 10px;
    top: -17.8px;
    height: 15px;
    width: 35%;
    background: $background;
    border: 3px solid $primary;
    border-bottom: none;
  }
  &::after {
    content: "";
    position: absolute;
    top: -18px;
    transform: skew(-45deg);
    right: 30%;
    background: $background;
    border-left: 4px solid $primary;
    border-top: 3px solid $primary;
    height: 15px;
    width: 30px;
  }

  h1 {
    color: white;
    font-size: 1.75rem;
    margin-bottom: 15px;
  }
  .content {
    color: lightgrey;
    font-size: 1.1rem;
    line-height: 1.5em;
  }
  aside{
      align-self: flex-end;
      display: flex;
      align-items: center;
      gap: 1rem;
  }
  ion-icon {
    right: 10px;
    bottom: 10px;
    color: $primary;
    font-size: 2.5rem;
    transition: all 0.2s ease;
    &:hover {
      cursor: pointer;
      color: white;
      transform: translateY(-5px);
    }
  }
}

@keyframes appear{
    to{
        opacity: 1;
    }
}
