@import "../../App.scss";

#Portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  .title{
      position: relative;
      font-size: $header-size;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
      letter-spacing: 7px;
      &::after{
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform:translateX(-50%);
          height: 3px;
          width: 110%;
          background: $primary;
      }
  }
}
