@import "../../App.scss";

#Experience {
  display: flex;
  align-items: center;
  justify-content: center;
}
.experience {
  max-width: $content-width;
  position: relative;
  font-family: $primary-font;
  header {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    h1 {
      position: relative;
      font-size: $header-size;
      font-weight: 300;
      color: white;
      text-transform: uppercase;
      letter-spacing: 7px;
      span {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: -5px;
          height: 3px;
          width: calc(100% + 30px);
          background: $primary;
        }
      }
    }
    .subtext {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-top: 20px;
      @media screen and (max-width: 580px) {
        flex-direction: column;
        gap: 10px;
      }
      h3 {
        &:first-of-type {
          font-size: clamp(1.25rem, 2vw, 1.5rem);
          color: white;
          span {
            color: $primary;
          }
        }
        color: $secondary-font-color;
        font-size: clamp(1.1rem, 2vw, 1.25rem);
      }
    }
  }
  .achievements {
    color: lightgrey;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    @media screen and (max-width: 920px) {
      gap: 1rem;
    }
    li {
      font-size: clamp(1.25rem, 2vw, 1.5rem);
      padding-left: 10px;
      margin-left: 20px;
      &::marker {
        content: "➤";
        color: $primary;
        font-size: 1.5rem;
      }
    }
  }
}

.download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      ion-icon,
      p {
        color: white;
        transform: translateY(-5px);
      }
    }
  }

  ion-icon {
    color: $primary;
    font-size: clamp(2.25rem, 3vw, 3rem);
    transition: all 0.2s ease;
  }
  p {
    color: $primary;
    transition: all 0.2s ease;
    transition-delay: 0.075s;
    font-size: clamp(1rem, 3vw, 1.25rem);
  }
}
