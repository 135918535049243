@import "../../App.scss";

#About {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .intro {
    max-width: $section-max-width;
    font-family: "Poppins", sans-serif;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    .title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      align-self: flex-start;
      h3 {
        color: $secondary-font-color;
        align-self: flex-end;
        font-size: clamp(1rem, 2vw, 1.5rem);
      }
    }

    div {
      position: relative;
    }
    h1 {
      flex-basis: auto;
      position: relative;
      text-transform: uppercase;
      font-size: clamp(3rem, 16vw, 10rem);
      font-family: $secondary-font;
      color: transparent;
      -webkit-text-stroke: 2px var(--clr);
      &.fill {
        position: absolute;
        top: 0;
        left: 0;
        color: var(--clr);
        width: 0;
        overflow-x: hidden;
      }
    }
    p {
      color: lightgrey;
      font-size: clamp(1.2rem, 3vw, 1.7rem);
      span{
          color: $primary;
      }
    }
  }
}
.traits-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    gap: 3rem;
}
