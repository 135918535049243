@import "../../../App.scss";

.featured {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $primary-font;
  &.reverse{
    flex-direction: row-reverse;
  }
  .content {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .mobile-view-site{
      display: none;
    }
    header {
      &.reverse{
        text-align: right;
      }
      h3 {
        color: $primary;
      }
      h1 {
        color: white;
        font-size: 2rem;
      }
    }
    p {
      border: 2px solid $primary;
      border-radius: 10px;
      padding: 1.5rem;
      font-size: 1.25rem;
      line-height: 1.25em;
      max-width: 600px;
      color: lightgrey;
      background: $background;
    }
    .stack{
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      h4{
        color: white;
        text-wrap: nowrap;
        font-weight: 300;
        background:#51dfff41;
        border: 1px solid #dcf9ff69;
        padding: 5px;
        border-radius: 5px;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
      }
    }
  }
  a{
    transition: all .2s ease;
    display: inline-block;
    position: relative;
  }
  .image {
    width: clamp(500px, 32vw, 600px);
    margin-left: -100px;
    max-height: 500px;
    overflow-y: hidden;
    border-radius: 5px;
    position: relative;
    &.reverse{
      margin-right: -100px;
      margin-left: unset;
    }
    &::after{
      display: flex;
      justify-content: center;
      align-items: center;
      content: 'Visit Site';
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 5px;
      font-size: 32px;
      color: white;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,.2);
      backdrop-filter: blur(5px);
      pointer-events: none;
      opacity: 0;
      transition: all .2s ease;
    }
    &:hover{
      cursor: pointer;
      &::after{
        opacity: 1;
      }
    }
    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

@media screen and (max-width: 920px) {
  .featured, .reverse {
    flex-direction: column-reverse !important;
    align-items: center;
    header{
      text-align: center !important;
    }
    .content {
      background: $background;
      margin-top: -100px;
      border: 3px solid $primary;
      border-radius: 10px;
      padding: 1rem;
      width: clamp(400px, 75vw, 600px);
      p {
        border: unset;
        padding: unset;
      }
    }
    .image {
      width: 100%;
      margin-left: unset;
    }
  }
}

@media screen and (max-width: 700px) {
  .featured {
      margin-top: 70px;
      position: relative;
    .content {
      height: auto;
      width: 100%;
      overflow:hidden;
      a{
        display: inline;
        align-self: center;
      }
      .mobile-view-site{
        text-align: center;
        display: inline-block;
        p{
          text-transform: uppercase;
          letter-spacing: 5px;
          color: white;
        }
        ion-icon{
          font-size: 50px;
          color: white;
          &:hover{
            cursor: pointer;
          }
        }
      }
      .mobile-background{
          z-index: -1;
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-size: cover;
          background-position: center;
          &::after{
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: rgba(0,0,0,.7);
            backdrop-filter: blur(2px);
            -webkit-backdrop-filter: blur(2px);
          }
      }
      p{
          background: transparent;
      }
    }
    .image{
        display: none;
    }
  }
}
