@import "../../../App.scss";

.trait {
  div {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;
    height: clamp(250px, 25vw, 350px);
    width: clamp(250px, 25vw, 350px);
    border-radius: 50%;
    border: 3px solid $primary;
    user-select: none;
    padding: 80px;
    @media screen and (max-width: 768px){
        padding: 50px;
    }
    overflow: hidden;
    &:hover {
      cursor: pointer;
    }
    .inset{
      display: inline-block;
      position: absolute;
      inset: 5px;
      border: 5px solid $background;
      border-radius: 10px;
      opacity: 0;
    }
    .shine{
        display: none;
        position: absolute;
        left: -50%;
        height: 100%;
        width: 70px;
        background: $primary;
        transform: skew(40deg);
    }

    h1 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: clamp(3rem, 5vw, 4rem);
      letter-spacing: -3px;
      text-transform: uppercase;
      -webkit-text-stroke: 2px $primary;
      color: transparent;
      font-family: $secondary-font;
    }
    ion-icon{
        position: absolute;
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        color: white;
        font-size: clamp(3rem, 5vw, 4rem);
        color: $primary;
    }
    p {
      transform: translateX(100px);
      opacity: 0;
      color: $background;
      font-size: clamp(1.2rem, 3vw, 1.5rem);
      line-height: 1.35em;
    }
  }
}
